<div class="form-group row">
  <div class="col-md-12">
    <div
      class="custom-upload mb-3"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.disabled]="isUploading()"
      [class.drag-over]="isDragOver">

      <input type="file" id="file" (change)="onFileChange($event)" [disabled]="isUploading()" />

      @if(file){
        <label class="btn" for="file">
          <div>
            <div class="uploaded-file justify-content-between align-items-center">
              <div class="d-flex">
                <img class="me-2" src="assets/images/icons/icon-pdf.svg" width="20"/>
                {{ file.original_name | truncate:25 }}
                <small class="ms-2" style="margin-top: 3px">{{file.size | fileSize}}</small>
              </div>
              <div class="delet-icon" (click)="remove()">
                <img src="assets/images/icons/icon-delete.svg" />
              </div>
            </div>
            <div class="upload-text mt-3">Upload & Replace</div>
          </div>
        </label>
      }@else {
        <label class="btn" for="file">
          <div>
            <img src="assets/images/icons/icon-upload-img.svg" />
            <div class="drag-text">
              Drag and drop Files here or click here to upload
            </div>
            <div class="upload-text">
              @if(isUploading()){ Uploading... ({{ progress }}%) } @else { Upload }
            </div>
          </div>
        </label>
      }
    </div>
  </div>
</div>

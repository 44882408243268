import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';

@Component({
  selector: 'app-side-form-button-group',
  standalone: true,
  imports: [CancelButtonComponent, SavingButtonComponent],
  templateUrl: './side-form-button-group.component.html',
  styleUrl: './side-form-button-group.component.scss',
})
export class SideFormButtonGroupComponent {
  @Input() formGroup: FormGroup;
  @Input() buttonPosition: string;
  @Input() cancelButtonTitle: string;
  @Input() submitButtonAndNewTitle: string;
  @Input() submitButtonAndNewLoadingTitle: string;
  @Input() submitButtonTitle: string;
  @Input() submitButtonLoadingText: string;

  @Output() cancelRequest = new EventEmitter<void>();
  @Output() saveRequest = new EventEmitter<boolean>();

  requestClose() {
    this.cancelRequest.emit();
  }

  requestSubmitForm(isNew: boolean = true) {
    this.saveRequest.emit(isNew);
  }
}

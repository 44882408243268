import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import flatpickr from 'flatpickr';
import { DatePickConfig } from '../../../../models/form.config';
import { AppSettingsStore } from '../../../../../core/store/app-settings.store';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @Input() id: string;
  @Input() placeholder: string;
  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() datePickConfig: DatePickConfig = {};
  @Input() type: 'datepicker' | 'datetimepicker' = 'datepicker';

  @ViewChild('flatpickrInput', { static: true }) input: ElementRef;

  private flatpickrInstance: flatpickr.Instance | null = null;
  private onChange: (value: Date) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private appSettingsStore: AppSettingsStore) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initializeFlatpickr();
  }

  writeValue(value: Date): void {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.setDate(value, false);
    }
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.disabled = isDisabled;
    }
  }

  private initializeFlatpickr(): void {
    const options: flatpickr.Options.Options = {
      mode: this.datePickConfig.mode || 'single',
      minDate: this.datePickConfig.minDate || undefined,
      dateFormat:
        this.type === 'datetimepicker'
          ? this.appSettingsStore.dateTimePickerFormat
          : this.appSettingsStore.datePickerFormat,
      enableTime: this.type === 'datetimepicker',
      defaultDate: this.formGroup.value[this.formControlName],
      onChange: this.onFlatpickrChange.bind(this),
    };

    this.flatpickrInstance = flatpickr(this.input.nativeElement, options);
  }

  private onFlatpickrChange(
    selectedDates: Date[],
    dateStr: string,
    instance: flatpickr.Instance
  ): void {
    this.formGroup.patchValue({
      [this.formControlName]: dateStr,
    });
  }

  ngOnDestroy(): void {
    this.destroyFlatpickr();
  }

  private destroyFlatpickr(): void {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
      this.flatpickrInstance = null;
    }
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { InfoIconComponent } from '../controls/info-icon/info-icon.component';
import { DatePickConfig, FormSection } from '../../../models/form.config';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MandatoryFieldIndicatorComponent } from '../controls/mandatory-field-indicator/mandatory-field-indicator.component';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  switchMap,
} from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { SingleFileUploadComponent } from '../controls/single-file-upload/single-file-upload.component';
import { MultipleFileUploadComponent } from '../controls/multiple-file-upload/multiple-file-upload.component';
import { SideFormButtonGroupComponent } from '../side-form-button-group/side-form-button-group.component';
import { MainLoaderComponent } from '../../loaders/main-loader/main-loader.component';
import { DatePickerComponent } from '../controls/date-picker/date-picker.component';
import { getStatusLabelClass } from '../../../../core/modals/status.model';

@Component({
  selector: 'app-side-form',
  standalone: true,
  imports: [
    InfoIconComponent,
    SavingButtonComponent,
    CancelButtonComponent,
    TranslateModule,
    MandatoryFieldIndicatorComponent,
    ReactiveFormsModule,
    NgSelectModule,
    CommonModule,
    FormsModule,
    FormErrorComponent,
    SingleFileUploadComponent,
    MultipleFileUploadComponent,
    SideFormButtonGroupComponent,
    MainLoaderComponent,
    DatePickerComponent,
  ],
  templateUrl: './side-form.component.html',
  styleUrl: './side-form.component.scss',
})
export class SideFormComponent implements OnInit {
  @Input() title: string;
  @Input() buttonPosition: string = 'top';
  @Input() submitButtonTitle: string;
  @Input() submitButtonLoadingText: string;
  @Input() submitButtonAndNewTitle: string;
  @Input() submitButtonAndNewLoadingTitle: string;
  @Input() cancelButtonTitle: string;
  @Input() formGroup: FormGroup;
  @Input() formSections: FormSection[];
  @Input() isLoading: boolean;

  @Output() formSubmitRequest = new EventEmitter<boolean>();
  @Output() closeRequest = new EventEmitter();
  subscriptions: Subscription[] = [];

  public typeaheadInputs: Map<string, Subject<string>> = new Map();
  public options$: Map<string, Observable<any[]>> = new Map();

  ngOnInit() {
    this.setupFormFields();
  }

  setupFormFields() {
    this.formSections.forEach((section) => {
      section.fields.forEach((field) => {
        if (!this.formGroup.contains(field.formControlName)) {
          this.formGroup.addControl(
            field.formControlName,
            new FormControl('', this.bindValidations(field.validators))
          );
        }

        if (field.type === 'autocomplete') {
          const control = new FormControl();
          this.formGroup.addControl(field.formControlName, control);

          const input$ = new Subject<string>();
          this.typeaheadInputs.set(field.formControlName, input$);

          const options$ = input$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((value) => this.loadOptions(value, field)),
            catchError((error) => {
              console.error('Error in autocomplete search', error);
              return of([]);
            })
          );
          this.options$.set(field.formControlName, options$);
        }
      });
    });
  }

  onTypeahead(searchText: string, formControlName: string) {
    const input$ = this.typeaheadInputs.get(formControlName);
    if (input$) {
      input$.next(searchText);
    }
  }

  loadOptions(value: string, field: any): Observable<any[]> {
    if (
      !value ||
      !field.serviceMethod ||
      typeof field.service[field.serviceMethod] !== 'function'
    ) {
      return of([]);
    }
    return field.service[field.serviceMethod]('', '', value);
  }

  bindValidations(validators: string[]): any[] {
    const validList = [];
    if (validators.includes('required')) {
      validList.push(Validators.required);
    }
    if (validators.includes('email')) {
      validList.push(Validators.email);
    }
    return validList;
  }

  requestClose() {
    this.closeRequest.emit();
  }

  requestSubmitForm(isCloseModal: boolean = true) {
    this.formSubmitRequest.emit(isCloseModal);
  }

  getClass(color: string) {
    return getStatusLabelClass(color);
  }
}

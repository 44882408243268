<div class="form-group row">
  <div class="col-md-12">
    <div
      class="custom-upload mb-3"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [class.drag-over]="isDragOver">
      <input type="file" multiple id="file" (change)="onFileChange($event)" />
      <label class="btn" for="file">
        <div>
          <img src="assets/images/icons/icon-upload-img.svg" />
          <div class="drag-text">
            Drag and drop Files here or click here to upload
          </div>
          <div class="upload-text">Upload</div>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="row">
  @for (file of files; track $index) {
    <div class="col-md-4 custom-upload mb-3">
      <div class="uploaded-file justify-content-between align-items-center">
        <div class="d-flex">
          <img class="me-2" src="assets/images/icons/icon-pdf.svg" width="20" />
          {{ file.original_name | truncate : 25 }}
          <small class="ms-2" style="margin-top: 3px">{{file.size | fileSize}}</small>
          @if(progress[file.file_name] > 0 && progress[file.file_name] < 100 ){
            <small class="ms-2" style="margin-top: 3px">({{progress[file.file_name]}}%)</small>
          }
        </div>
        @if(file.is_deleted_file){
          <div class="delet-icon" (click)="revertFile(file)">
            <img src="assets/images/icons/icon-fresh.svg" />
          </div>
        }@else {
          <div class="delet-icon" (click)="removeFile(file)">
            <img src="assets/images/icons/icon-delete.svg" />
          </div>
        }
      </div>
    </div>
  }
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-mandatory-field-indicator',
  standalone: true,
  imports: [],
  templateUrl: './mandatory-field-indicator.component.html',
  styleUrl: './mandatory-field-indicator.component.scss',
})
export class MandatoryFieldIndicatorComponent {}

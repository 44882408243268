@if(buttonPosition == 'bottom') {
    <app-cancel-button
      [label]="cancelButtonTitle"
      (cancelRequest)="requestClose()">
    </app-cancel-button>

    @if(submitButtonAndNewTitle) {
      <app-saving-button
        [isValid]="formGroup.valid"
        [label]="submitButtonAndNewTitle"
        [savingLabel]="submitButtonAndNewLoadingTitle"
        (saveRequest)="requestSubmitForm(false)">
      </app-saving-button>
    }

    <app-saving-button
      [isValid]="formGroup.valid"
      [label]="submitButtonTitle"
      [savingLabel]="submitButtonLoadingText"
      (saveRequest)="requestSubmitForm()">
    </app-saving-button>
}
